<script setup>
import { useStatisticsStore } from "@/store/statistics"
import { onMounted, ref, reactive, watch, inject } from "vue"
import { useRoute, useRouter } from "vue-router"
import Icon from "@/components/Icon"
import { useLoginStore } from "../store/login";
import moment from 'moment'

const ScreenState = inject('ScreenProvider')
const LoginStore = useLoginStore()
const StatisticsStore = useStatisticsStore()
const Router = useRouter()
const Route = useRoute()
const inputGroup = ref()
const CardOrder = ['A','2','3','4','5','6','7','8','9','0','J','Q','K']

const matchValue = reactive(Array(6).fill(""))
const focusIndex = ref(0)

LoginStore.$subscribe(mutation => {
  console.log(mutation);
  console.log(LoginStore.currentUser.user_exp_at);
  if (!LoginStore.currentUser.user_exp_at || parseInt(moment(LoginStore.currentUser.user_exp_at*1000).diff(moment()) / 3600000 / 24) <= 0) {
    alert('您的時數已過期，請至儲值中心')
  }
})

onMounted(async () => {
  if (!LoginStore.currentUser.user_exp_at || parseInt(moment(LoginStore.currentUser.user_exp_at*1000).diff(moment()) / 3600000 / 24) <= 0) {
    alert('您的時數已過期，請至儲值中心')
    return Router.replace('/shop')
  }
  if (Route.query.guid) {
    const res = await StatisticsStore.reloadGame(Route.query.guid)
    if (res.status !== 200) {
      const res = await StatisticsStore.createGame(LoginStore.currentUser.user_id)
      console.log(res);
      if (res.errorState && res.errorState === 503) {
        alert('重複登入，無法進行操作')
      }
      Router.replace({  path: '/game' ,query: { guid: res.game_uid } })
    }
    const reload = confirm('您是否要繼續尚未結束的殘局？')
    if (!reload) {
      closeGame()
    }
  } else {
    const res = await StatisticsStore.createGame(LoginStore.currentUser.user_id)
    if (res.errorState && res.errorState === 503) {
      alert('重複登入，無法進行操作')
    }
    Router.replace({  path: '/game' ,query: { guid: res.game_uid } })
  }
})

watch(focusIndex, index => {
  let sibling = inputGroup.value.querySelector(`input[data-index="${index}"]`)
  sibling.focus()
})

watch(Route, async newRoute => {
  if (newRoute.fullPath === '/game') {
    const res = await StatisticsStore.createGame('2')
    Router.replace({  path: '/game' ,query: { guid: res.game_uid } })
  }
})

function focusNext(index, e) {
  if (e.data) {
    if (index === 5) return
    focusIndex.value = index + 1
  }
}

function handleKeyUp(e) {
  console.log(e.code);
  if (e.code === 'Backspace' || e.code === 'Delete') return focusIndex.value = focusIndex.value === 0 ? 0 : focusIndex.value - 1
  if (e.code === 'Enter' || e.code === 'NumpadEnter') return submitLog()
  if (e.code==='ArrowRight') return focusIndex.value = focusIndex.value === 5 ? 5 : focusIndex.value + 1
  if (e.code==='ArrowLeft') return focusIndex.value = focusIndex.value === 0 ? 0 : focusIndex.value - 1
  if (e.code === 'Escape') {
    Array(6).fill().forEach((e, index) => matchValue[index]="")
    inputGroup.value.querySelector(`input[data-index="0"]`).focus()
  }
}

function checkPivotLeave(clearMatchVal) {
  const matchValPivot = clearMatchVal.reduce((acc, c) => {
    if (acc[c === '1' ? 'A' : c.toUpperCase()]) acc[c === '1' ? 'A' : c.toUpperCase()] += 1
    else acc[c === '1' ? 'A' : c.toUpperCase()] = 1
    return acc
  }, {})
  for (const card in matchValPivot) {
    let cardLeave = 32 - (StatisticsStore.pivotDict[card] + matchValPivot[card])
    if (cardLeave < 0) {
      alert(`牌面 ${card === '0' ? '10' : card} 僅剩餘 ${matchValPivot[card]+cardLeave}張，出牌數不可超過剩餘張數`)
      return false
    }
  }
  return true
}

async function submitLog() {
  const clearMatchVal = matchValue.filter(e => e)
  if (clearMatchVal.some(e => !['1','2','3','4','5','6','7','8','9','0','A','a','J','j','Q','q','K','k'].includes(e))) {
    return alert('只能輸入0~9數字以及 A、J、Q、K大小寫英文')
  }
  if (!checkPivotLeave(clearMatchVal)) return
  const clearMatchValue = clearMatchVal.map(e => {
    if (e === '1') return "A"
    return e.toUpperCase()
  })
  Array(6).fill().forEach((e, index) => matchValue[index]="")
  const res = await StatisticsStore.addCardLog(clearMatchValue)
  console.log(res);
  if (res.errorState === 503) {
    return alert('重複登入，無法進行操作')
  }
  focusIndex.value = 0
  inputGroup.value.querySelector(`input[data-index="0"]`).focus()
}

async function closeGame() {
  const ensure = confirm('確定要結束此局嗎？')
  if (ensure) {
    Router.replace('/')
  }
}

const pivotVisiable = ref(false)
function toggleShowPivot(bool) {
  pivotVisiable.value = bool
}

const keyboardVisiable = ref(false)
function toggleShowKeyboard(bool) {
  keyboardVisiable.value = bool
}

function mobileKeyIn(key) {
  matchValue[focusIndex.value] = key
  if (focusIndex.value !== 5) {
    focusIndex.value += 1
  }
}

function deleteKey() {
  matchValue[focusIndex.value] = ""
  if (focusIndex.value !== 0) {
    focusIndex.value -= 1
  }
}

async function cleanLastMatch() {
  await StatisticsStore.deleteLastMatch()
}

//四捨五入取小數點後四位
function roundToFour(num) {
  return +(Math.round(num + "e+4")  + "e-4");
}
</script>

<template>
  <div class="view-game">
    <section class="operation">
      <div class="keyin-container" >
        <div class="keyin" v-if="ScreenState.mode === 'COMPUTER'">
          <h3>輸入牌面</h3>
          <div class="input-group" ref="inputGroup">
            <template v-for="mv, index in matchValue" :key="`match_value_${index}`">
              <input :data-index="index" type="text" maxlength="1" v-model="matchValue[index]" @input="e => focusNext(index, e)" @keyup="e => handleKeyUp(e)">
            </template>
          </div>
          <div class="button-group">
            <button @click="submitLog">送出</button>
            <button @click="cleanLastMatch">清除上一局</button>
          </div>
          <span class="extra">ACE請輸入『1』, 10請輸入為『0』, JQK請輸入為『J,Q,K』Enter鍵輸入，Esc鍵重設</span>
        </div>
        <div class="currentValue"><h3>剩餘牌數</h3><p>{{ StatisticsStore.leaveCards }}</p></div>
      </div>
      <button v-if="ScreenState.mode === 'COMPUTER'" class="close-game" @click="closeGame"><icon icon="door-closed-fill"/>結束此局</button>
    </section>

    <main>
      <div class="keyboard-control" v-if="ScreenState.mode === 'MOBILE'">
        <button @click="toggleShowKeyboard(true)">輸入牌面</button>
        <button @click="cleanLastMatch">清除上一局</button>
      </div>
      
      <div class="match-log" :style="{ overflow: `${pivotVisiable ? 'hidden' : 'scroll'}` }">
        <div class="wrapper">
          <div class="match" v-for="match, index in StatisticsStore.cardLog" :key="`log_match_${index}`">
            <div class="card" v-for="card, cindex in match" :key="`card_m${index}_${cindex}`">
              {{ card === '0' ? '10' : card }}
            </div>
          </div>
        </div>
        
        <Transition name="slide-right">
          <div class="leave-cards" v-if="ScreenState.mode === 'COMPUTER' || pivotVisiable">
            <!-- <span class="close" @click="toggleShowPivot(false)"><icon icon="chevron-bar-right" /></span> -->
            <header>剩餘牌數</header>
            <div class="cards-wrapper">
              <div class="card" v-for="card in CardOrder" :key="`leave_cards_${card}`">
                {{ card === '0' ? '10' : card }}
                <span>{{ StatisticsStore.pivotDict[card] ? 32-StatisticsStore.pivotDict[card] : 32 }}</span>
              </div>
            </div>
          </div>
        </Transition>

        <!-- <Transition name="fade-in">
          <span class="show-pivot" v-if="!pivotVisiable && ScreenState.mode === 'COMPUTER'" @click="toggleShowPivot(true)"><icon icon="chevron-bar-left" />顯示各牌面剩餘數</span>
        </Transition> -->
      </div>
      <div class="match-log-title" v-if="ScreenState.mode === 'MOBILE'">
        <span :class="{focus: !pivotVisiable}" @touchstart="toggleShowPivot(false)">牌面紀錄</span>
        <span :class="{'show-pivot': true, focus: pivotVisiable}" @touchstart="toggleShowPivot(true)">各牌面剩餘數</span>
      </div>
      <div class="suggest">
        <div :class="{'suggest-card': true, active: StatisticsStore.BPValue === 'BANKER'}">
          <div class="content">
            <p>基準Z</p>
            <span>莊家</span>
          </div>
          <div class="light">
            <icon icon="coin" />
          </div>
          <span class="extra">{{ StatisticsStore.getBPVal }}</span>
        </div>

        <div :class="{'suggest-card': true, active: StatisticsStore.BPValue === 'PLAYER'}">
          <div class="content">
            <p>基準 Z</p>
            <span>閒家</span>
          </div>
          <div class="light">
            <icon icon="coin" />
          </div>
          <span class="extra">{{ StatisticsStore.getBPVal }}</span>
        </div>

        <div :class="{'suggest-card': true, active: StatisticsStore.dragonVal > 4}">
          <div class="content">
            <p>基準 4</p>
            <span>閒龍寶</span>
          </div>
          <div class="light">
            <icon icon="coin" />
          </div>
          <span class="extra">{{ roundToFour(StatisticsStore.dragonVal) }}</span>
        </div>
        
        <div :class="{'suggest-card': true, active: StatisticsStore.superSixVal > 12}">
          <div class="content">
            <p>基準 12</p>
            <span>超級六</span>
          </div>
          <div class="light">
            <icon icon="coin" />
          </div>
          <span class="extra">{{ roundToFour(StatisticsStore.superSixVal) }}</span>
        </div>
        
        <div :class="{'suggest-card': true, active: StatisticsStore.equalVal > 10}">
          <div class="content">
            <p>基準 10</p>
            <span>和</span>
          </div>
          <div class="light">
            <icon icon="coin" />
          </div>
          <span class="extra">{{ roundToFour(StatisticsStore.equalVal) }}</span>
        </div>
        
        <div :class="{'suggest-card': true, active: StatisticsStore.PairStandard !== null && StatisticsStore.getPairVal > StatisticsStore.PairStandard}">
          <div class="content">
            <p>基準 X</p>
            <span>對子</span>
          </div>
          <div class="light">
            <icon icon="coin" />
          </div>
          <span class="extra">{{ StatisticsStore.getPairVal }}</span>
        </div>
      </div>
    </main>
  </div>

  <Transition name="keyboard-slide-bottom">
    <section class="keyboard-container" v-if="ScreenState.mode === 'MOBILE' && keyboardVisiable">
      <header>
        <div ref="inputGroup" class="wrapper">
          <template v-for="mv, index in matchValue" :key="`match_value_${index}`">
            <input :class="{ focus: focusIndex === index }" :data-index="index" type="text" maxlength="1" v-model="matchValue[index]" @touchstart="() => focusIndex = index" readonly>
          </template>
        </div>
        <button @touchstart="toggleShowKeyboard(false)"><icon icon="chevron-bar-down" /></button>
      </header>

      <main>
        <button class="disable-dbl-tap-zoom" v-for="card in CardOrder" @touchstart="mobileKeyIn(card)" :key="`keyboard_bottom_${card}`">{{ card === '0' ? '10' : card }}</button>
        <button class="back-space disable-dbl-tap-zoom" @touchstart="deleteKey()"><icon icon="backspace"/></button>
        <button class="submit disable-dbl-tap-zoom" @touchstart="submitLog">送出</button>
      </main>
    </section>
  </Transition>
</template>
