import { defineStore } from "pinia";
import { fetchBijaApi } from "../utils";
import ValueTable from "../utils/valueTable.json"
import { useLoginStore } from "./login";

export const useStatisticsStore = defineStore({
  id: 'Statistics',
  state: () => ({
    gameUid: "",
    gameId: "",
    cardLog: []
  }),
  getters: {
    leaveCards() { //剩下總牌量
      if (this.cardLog.length) {
        return 416 - this.cardLog.reduce((acc, match) => acc + match.length, 0)
      }
      return 416
    },
    decks() { //剩餘牌組副數
      if (this.cardLog.length) {
        return this.leaveCards / 52
      }
      return 8
    },
    pivotDict() { //各牌面已出現張數統計（樞紐分析）
      if (this.cardLog.length) {
        return this.cardLog.reduce((acc, log) => {
          acc = log.reduce((iacc, card) => {
            iacc[card] += 1
            return iacc
          }, acc)
          return acc
        }, { "A": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0, "9": 0, "0": 0, "J": 0, "Q": 0, "K": 0 })
      }
      return {}
    },
    dragonVal() { //龍寶真數
      const DragonTable = ValueTable['Dragon']
      return Object.entries(this.pivotDict).reduce((acc, [card, count]) => acc + DragonTable[card] * count, 0) / this.decks
    },
    superSixVal() { //超級六真數
      const SuperSixTable = ValueTable['SuperSix']
      Object.entries(this.pivotDict).reduce((acc, [card, count]) => {
        console.log('牌面：', card, '張數：', count, '權值：', SuperSixTable[card], '牌面總權值：', SuperSixTable[card] * count ,'權值累計', acc + SuperSixTable[card] * count);
        return acc + SuperSixTable[card] * count
      }, 0)
      return Object.entries(this.pivotDict).reduce((acc, [card, count]) => acc + SuperSixTable[card] * count, 0) / this.decks
    },
    equalVal() { //合真數
      const DragonTable = ValueTable['Equal']
      return Object.entries(this.pivotDict).reduce((acc, [card, count]) => acc + DragonTable[card] * count, 0) / this.decks
    },
    getPairVal() { //對子總權值
      const PairValueTable = ValueTable['PairValue']
      if (this.leaveCards <= 78) {
        return Object.values(this.pivotDict).reduce((acc, count) => {
          return acc + PairValueTable[32-count]
        }, 0)
      }
      return 0
      
    },
    PairStandard() {
      const PareCompare = ValueTable['PareCompare']
      if (this.leaveCards <= 78) {
        return PareCompare[this.leaveCards]
      }
      return null
    },
    getBPVal() {
      const BPValueTable = ValueTable['BPCompare']
      return Object.entries(this.pivotDict).reduce((acc, [card, count]) => acc + BPValueTable[card] * count, 0)
    },
    BPValue() {
      // if (this.cardLog.length < 2) return null
      // const BPValueTable = ValueTable['BPCompare']
      // const lastBPValue = this.getBPVal - this.cardLog[0].reduce((acc, c) => acc + BPValueTable[c], 0)
      // if (this.getBPVal > 0) {
      //   if (this.getBPVal > lastBPValue) return 'BANKER'
      // }
      // if (this.getBPVal < 0) {
      //   if (this.getBPVal < lastBPValue) return 'PLAYER'
      // }
      // return null
      const BPValueTable = ValueTable['BPCompare']
      if (this.cardLog.length >= 2) {
        const matchBP = this.cardLog.slice(0,2).map(m => m.reduce((acc, c) => acc + BPValueTable[c], 0))
        if (matchBP.every(v => v > 0) ) return 'BANKER'
        if (matchBP.every(v => v < 0)) return 'PLAYER'
        if (matchBP[0] === 0) {
          if (matchBP[1] > 0) return 'BANKER'
          if (matchBP[1] < 0) return 'PLAYER'
        }
        if (matchBP[1] === 0) {
          if (matchBP[0] > 0) return 'BANKER'
          if (matchBP[0] < 0) return 'PLAYER'
        }
        return null
      }
      return null
    }
  },
  actions: {
    async createGame(user_id) {
      this.cardLog = []
      const token = localStorage.getItem('winbar-token')
      const res = await fetchBijaApi('/game/new', { user_id, token })
      console.log(res);
      if (res.state === 'OK') {
        this.gameId = res.game_id
      }
      return res
    },
    async reloadGame(uid) {
      const res = await fetchBijaApi(`/game/reload?uid=${uid}`)
      console.log(res);
      if (res.gameId) {
        this.gameId = res.gameId
        this.cardLog = [...res.gameLogs.map(e => e.game_log_cards.split(','))]
        return { status: 200 }
      } else return { status: 404 }
    },
    async addCardLog(matchArray) {
      const LoginStore = useLoginStore()
      const token = localStorage.getItem('winbar-token')
      const res = await fetchBijaApi('/game/log', { game_id: this.gameId, log: matchArray.join(',') , token, user_id: LoginStore.currentUser.user_id})
      console.log(res);
      if (res.state === 'OK') {
        this.cardLog = [matchArray, ...this.cardLog]
      }
      return res
    },
    async deleteLastMatch() {
      const LoginStore = useLoginStore()
      const token = localStorage.getItem('winbar-token')
      const res = await fetchBijaApi('/game/removeLastLog', { game_id: this.gameId, token, user_id: LoginStore.currentUser.user_id })
      this.cardLog.splice(0, 1)
    },
    removeCardLog() {

    },
    async closeGame() {
      const res = await fetchBijaApi('/game/delete', { game_id: this.gameId })
      if (res.state === 'OK') {
        this.cardLog = []
        return { status: 200 }
      }
      return
    }
  }
})
